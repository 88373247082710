import React from 'react';
import { Helmet } from 'react-helmet';

import './global.css'

export const wrapRootElement = ({ element }) => {
    return (
        <>
        <Helmet>
            <link rel='icon' type="image/png" href='/logo_transparent.png'/>
        </Helmet>
          {element}
        </>
    );
  };